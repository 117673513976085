import React from 'react';
import './App.css';
import './Responsive.css';
import Header from "./containers/header"
import MuchoMas from "./containers/billetera/muchoMas.js"
import GroupImage from "./containers/groupImage"
import Footer from "./containers/footer"
import TrustUs from "./containers/trustUs"
import Loader from "./components/loader"

class App extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
      moduleToShow: "admin",
      isLoading: true
    }
  }

  handleHover(module){
    this.setState({moduleToShow: module})
  }
  
  componentDidMount() {
    this.timerHandle = setTimeout(() => this.setState({ isLoading: false }), 2300);   
  }

  componentWillUnmount(){
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  yourPage(){
    return(
        <>
          <Header/>
          <GroupImage handleHover={(module)=> this.handleHover(module)}/>
          <MuchoMas/>
          <TrustUs/>
          <Footer/> 
        </>
    ) 
  }

  render() {
    return (
      <>
        {  this.state.isLoading ? <Loader /> : this.yourPage()}
      </>
    );
  }
}

export default App;
