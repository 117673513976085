import styled from 'styled-components'

export const FormContainer = styled.form`
  width: 100%;
  height: 85vh;
  max-height: 670px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 650px) {
    height: auto;
}

`

export const Row = styled.div`
  width: 87%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  
  @media (max-width: 650px) {
    width: 75%; 
}
  
`

export const RowLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const InputWrapperMargin = styled.div`
  width: 100%;
  margin-right: 25px;
`

export const InputLabel = styled.label`
  display: block;
  
  font-size: 18px;
  padding: 10px 0;
`

export const ErrorLabel = styled.label`
  display: block;
  color: red;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const StyledButton = styled.button`
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 60px;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    background-color: #30735f;
    color: white;
    margin-bottom: 42px;
`

export const ButtonWrapper = styled.div`
text-align: center;
margin-top: 30px;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`

export const ErrorMessage = styled.label`
  margin-right: auto;
  font-weight: 500;
  
`
