import React from 'react'
import styled from 'styled-components'
import renaper from "../../assets/renaper.png"
import pdp from "../../assets/pdp.png"
import mi from "../../assets/mi.svg"
import appstore from "../../assets/appstore-logo.png"
import googleplay from "../../assets/google-play-badge.png"

const Container = styled.div`
    width: 95%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    margin: 5% 2.5%; 
    @media (max-width: 650px) {
        min-height:500px;
    }
`
const ContainerLogo = styled.div`
    width: 100%;
    max-height: 140px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
        max-height: 200px;
    }
`
const ConteinerText = styled.div`
    width: 100%;
    max-height: 140px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0%;
    @media (max-width: 650px) {
        flex-direction: column;
        width: 100%;
        max-height: 200px;
    }
`
const Logo = styled.img`
    max-height: 65px;
    margin: 0% 2%;
`
const ContainerGroup = styled.div`
    display: flex;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 2% 0%;
`
const ContainerGroupSVG = styled.div`
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin: 2% 0%;
`
const ContainerText = styled.div`
    display: flex;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    @media (max-width: 650px) {
        margin: ${(props)=> props.margin ? props.margin : "0"};
        width: 100%;
        align-items: center;
        position: relative;
        text-align: center;
    }
    
`
const ContainerGroupLogo = styled.div`
   display: flex;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0% 2% 2%;

    @media (max-width: 650px) {
        display: none;
    }
    
`
const TitleFooter = styled.div`
    font-weight: 900;
    font-size: 16px;
    line-height: 1.38;
    color: #000000;  
    @media (max-width: 650px) {
        
        width: 100%;
        align-items: center;
        position: relative;
    text-align: center;
    }
`
const SubTitleFooter = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 1.38;
    color: #000000;  
    @media (max-width: 650px) {
        
        width: 100%;
        align-items: center;
        position: relative;
    text-align: center;
    }
`
const ContainerFooter = styled.div`
    width: 100%;    
    margin: 1% 0%;
    display: flex;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
`
const FooterText = styled.div`
    height: 16px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;  
`
const Linea = styled.div`  
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background-color: #000000;
`

class Footer extends React.Component {

    render() {
        return (
        <Container>
           <Linea/>
           <ContainerLogo>
                <ContainerGroup>
                    <Logo src={mi} />
                </ContainerGroup>
                <ContainerGroup>
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=app.miiii.mibarrioapp">
                     <Logo src={googleplay} style={{marginLeft: "-1%"}}/>
                </a> 
                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/ar/app/miiii/id1460141483?l=en">
                    <Logo src={appstore} />
                </a> 
                </ContainerGroup>
                <ContainerGroupSVG>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Miiii-105035267523835/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                        <path fill="#30735f" fill-rule="nonzero" d="M21.847 0C9.8 0 0 9.8 0 21.847c0 12.045 9.8 21.847 21.847 21.847 12.045 0 21.847-9.802 21.847-21.847C43.694 9.8 33.894 0 21.847 0zm5.433 22.616h-3.554v12.668h-5.267V22.616h-2.504v-4.477h2.504v-2.897c0-2.074.985-5.315 5.315-5.315l3.903.015v4.347h-2.833c-.461 0-1.117.23-1.117 1.22v2.63h4.013l-.46 4.477z"/>
                    </svg>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/miiii/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                        <g  fill="#fff"  fill-rule="nonzero">
                            <circle cx="21.844" cy="21.843" r="21.843" fill="#30735f"/>
                            <g fill="#fff">
                                <path d="M17.121 16.078h-4.417a.355.355 0 0 0-.354.354v14.189c0 .196.158.355.354.355h4.417a.355.355 0 0 0 .355-.355V16.432a.355.355 0 0 0-.355-.354zM14.914 9.024A2.916 2.916 0 0 0 12 11.935a2.917 2.917 0 0 0 2.914 2.913 2.915 2.915 0 0 0 2.912-2.913 2.915 2.915 0 0 0-2.912-2.91zM28.355 15.725c-1.774 0-3.085.763-3.88 1.629v-.922a.355.355 0 0 0-.355-.354h-4.23a.355.355 0 0 0-.355.354v14.189c0 .196.159.355.355.355h4.407a.355.355 0 0 0 .355-.355V23.6c0-2.366.642-3.287 2.291-3.287 1.796 0 1.939 1.477 1.939 3.409v6.898c0 .196.159.355.355.355h4.408A.355.355 0 0 0 34 30.62v-7.783c0-3.517-.67-7.113-5.645-7.113z"/>
                            </g>
                        </g>
                    </svg>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/miiii.app">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                        <g  fill="#30735f"  fill-rule="evenodd">
                            <path fill="#30735f" fill-rule="nonzero" d="M21.949 0C34.02 0 43.898 9.877 43.898 21.949c0 12.072-9.877 21.949-21.95 21.949C9.878 43.898 0 34.02 0 21.948 0 9.879 9.877 0 21.949 0z"/>
                            <g fill="#fff">
                                <path fill-rule="nonzero" d="M27.28 10H16.587A6.594 6.594 0 0 0 10 16.587V27.28a6.594 6.594 0 0 0 6.586 6.587h10.695a6.594 6.594 0 0 0 6.587-6.587V16.587A6.594 6.594 0 0 0 27.28 10zm4.47 17.281a4.474 4.474 0 0 1-4.469 4.469H16.586a4.474 4.474 0 0 1-4.468-4.469V16.587a4.474 4.474 0 0 1 4.468-4.47h10.695a4.474 4.474 0 0 1 4.47 4.47V27.28z"/>
                                <path fill-rule="nonzero" d="M21.934 15.784a6.157 6.157 0 0 0-6.15 6.15 6.157 6.157 0 0 0 6.15 6.15 6.157 6.157 0 0 0 6.15-6.15 6.157 6.157 0 0 0-6.15-6.15zm0 10.182a4.037 4.037 0 0 1-4.033-4.032 4.037 4.037 0 0 1 4.033-4.032 4.037 4.037 0 0 1 4.032 4.032 4.037 4.037 0 0 1-4.032 4.032z"/>
                                <path d="M28.342 13.988a1.56 1.56 0 0 0-1.097.455 1.56 1.56 0 0 0-.456 1.098c0 .408.166.81.456 1.099.288.288.689.454 1.097.454.41 0 .809-.166 1.098-.454.29-.29.455-.69.455-1.099 0-.41-.165-.81-.455-1.098a1.56 1.56 0 0 0-1.098-.455z"/>
                            </g>
                        </g>
                    </svg>
                </a> 
                    
                </ContainerGroupSVG>
            </ContainerLogo>

            <ConteinerText>
                <ContainerText>
                    <TitleFooter>Oficina Nordelta</TitleFooter>
                    <SubTitleFooter><a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/K485VnTtJuqVPo3e9" style={{color: "black"}}>Av. de los Lagos 7008,<br/>
                        1670 Tigre - Buenos Aires, Argentina</a>
                    </SubTitleFooter>
                </ContainerText>
                <ContainerText margin={"5% 0% 5% 0%"} >
                    <TitleFooter>Télefono de contacto</TitleFooter>
                    <SubTitleFooter><a target="_blank" rel="noopener noreferrer" href={"tel:5491134574444"} style={{color: "black"}}>+54-911-34574444</a></SubTitleFooter>
                </ContainerText>
                <ContainerGroupLogo >
                    <Logo src={renaper} />
                    <Logo src={pdp} />
                </ContainerGroupLogo>
            </ConteinerText>
           <Linea/>
           <ContainerFooter>
               <FooterText>Copyright Miiii S.A Todos los derechos reservados</FooterText>
               <FooterText>Términos y Condiciones </FooterText>
           </ContainerFooter>
        </Container>
        )
    }
}


export default Footer
