import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import { Formik } from 'formik'
import * as Yup from 'yup'
import Input from '../../components/Input'

import {
  FormContainer, Row,  InputWrapper, InputLabel,
  ErrorLabel, ButtonWrapper, StyledButton} from './styled'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Container = styled.div`
    width: 100%;
    display: flex;
    clear: both;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    border-radius: 5px;
    box-shadow: 25px 25px 50px 0 rgba(0, 0, 0, 0.15);
`
const ContainerForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    
    border-radius: 6px;
    margin-top: 6%;  
    @media (max-width: 650px) {
        width:100%;
        margin-left: 0%;  
        margin-top: 50px;  
    
    }
`
const TitleForm = styled.div`
    width: 100%;
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.39;
    text-align: center;
    color: #30735f;
    @media (max-width: 650px) {
        font-size: 25px;
    }
`


const SubTitleForm = styled.div`
    width: 100%;
    height: 50px;
    font-size: 21px; 
    line-height: 2.38;
    text-align: center;
    color: #4a4a4a;
    margin: 2% 0%;
    @media (max-width: 650px) {
        font-size: 17px; 
        line-height: 1.89;
        height: 80px;
        margin: 2% 0%;
        width: 80%;
        align-self: center;
    }
`

const SelectInput = styled.select`
width: 100%;
height: auto;
background: white;

font-size: 14px;
border: none;

width: 100%;
padding: 10px 10px;
box-sizing: border-box;
outline: none;
font-size: 18px;
border-top: 0px;
border-right: 0px;
border-radius: 0px;
border-left: 0px;
border-bottom: 1px solid #eeeeee;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

class FormMaterialUI extends React.Component {

  constructor(props) {
    super(props);
    this.state = { mailSent: false, error: null, sending: false }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (setFieldValue, field, option) => {
    setFieldValue('option', option);
  }

  handleSubmit = async data => {
    console.log("entro handlesubmit",data)
    this.setState({ sending: !this.state.sending })
    
    const dataForm = {
      FullName: data.nombre,
      Type : data.perfil ,
      phonenumber: data.telefono,
      email: data.email,
      location: data.localidad,
    }

    axios({
      method: 'post',
      url: 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/embajadores-contact',
      data: dataForm,
    })
      .then(function (response) {
        console.log(response);
        if(response.status === 200)
        {this.setState({ sending: !this.state.sending, mailSent: true })}
        
      }.bind(this))
      .catch(function (response) {
        console.log(response);
        this.setState({ sending: !this.state.sending, mailSent: true })
      }.bind(this))
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <InputWrapper >
          <InputLabel>{'Nombre'}</InputLabel>
          <Input
            value={values.nombre}
            onChange={({ target: { value } }) => setFieldValue('nombre', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.nombre ? true : false}
          />
          {errors.nombre && <ErrorLabel>{errors.nombre}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <InputLabel>{'Perfil'}</InputLabel>
          <SelectInput value={values.perfil}
            onChange={({ target: { value } }) => setFieldValue('perfil', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.perfil ? true : false}
          >
              <option value="1-Administrador">Administrador</option>
              <option value="2-Proveedor">Proveedor</option>
              <option value="3-Vecino">Vecino</option>
          </SelectInput>

          {errors.perfil && <ErrorLabel>{errors.perfil}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Email'}</InputLabel>
          <Input
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Telefono'}</InputLabel>
          <Input
            value={values.mensaje}
            onChange={({ target: { value } }) => setFieldValue('telefono', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.telefono ? true : false}
          />
          {errors.telefono && <ErrorLabel>{errors.telefono}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Localidad'}</InputLabel>
          <Input
            value={values.localidad}
            onChange={({ target: { value } }) => setFieldValue('localidad', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.localidad ? true : false}
          />
          {errors.localidad && <ErrorLabel>{errors.localidad}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {
        this.state.sending ?
          'Enviando mensaje...'
          :
          null
      }
      <ButtonWrapper>
      {
        this.state.mailSent ?
          'Mensaje enviado!'
          :
          <StyledButton type="submit"  >
          Enviar
        </StyledButton>
      }
      </ButtonWrapper>    
      </FormContainer>
  )

  getSchema = () =>
    Yup.object().shape({
      nombre: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required('Nombre es requerido'),
      email: Yup.string()
        .email('El Mail es inválido')
        .required('Mail es requerido'),
      telefono: Yup.string()
       .matches(phoneRegExp, 'Telefono invalido')
       .required('Telefono es requerido'),
      localidad: Yup.string()
        .min(2, 'La localidad debe tener un mínimo de 2(dos) caracteres')
        .required('localidad es requerido')
    })

  getInitialValues = () => ({
    neighbourhood: ''
  })

  render() {
    console.log(this.state)
    return (
      <Container>
        <ScrollAnimation animateIn='slideInUp' animateOnce={true}>
          <ContainerForm>
            <TitleForm>Convertite en embajador</TitleForm>
            <SubTitleForm>Completá tus datos y nos contactaremos a la brevedad</SubTitleForm>
            <Formik
              initialValues={this.getInitialValues()}
              validateOnChange={false}
              validationSchema={this.getSchema()}
              onSubmit={(data)=> this.handleSubmit(data)}
              render={e => this.getFormContent(e)} />
          </ContainerForm>
        </ScrollAnimation>
      </Container >
    );
  }
}
export default FormMaterialUI;
