import React from 'react'
import styled from 'styled-components'
import $ from "jquery"

import pasouno from '../assets/paso1.svg';
import pasodos from '../assets/paso2.svg';
import paso1tres from '../assets/paso3.svg';


export const Title = styled.div`
  width: 100%;
  height: 100px;
  font-size: 36px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #0c2b3d;
  margin-top: 3%;

    @media (max-width: 650px) {
        color: #0c2b3d;
        font-size: 30px;
        margin: 65px 0px 50px 0px;
    }
`

export const ContainerGlobal = styled.div`
    justify-content: center;
    width: 100%;
    
    @media (max-width: 650px) {
        justify-content: center;
        margin-left: 5%;
        width: 90%;  
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 90%;
    margin: 0% 6%;
    transition: all 1s ease-in-out;

    @media (max-width: 650px) {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

    }
`

export const ImageSteps = styled.img`
  
`

export const ImageStyled = styled.img`
    position: relative;
    width: 100%;     
    cursor: pointer;  
    margin-right: ${(props) => props.margin ? "15px" : ""};
    &:hover{
        transition: all 0.5s ease-in;
        transform: scale(1.1,1.1) ;
    }     
    @media (max-width: 650px) {
        display: none;
    }
`

export const Label = styled.div`
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    @media (max-width: 650px) {
        bottom: 40px;
        width: 100%;
        font-size: 20px;
        text-align: center;
        display: contents;
    }

`

export const ContainerImage = styled.div`
    position: relative;
    transition: all 1s  ease-in-out;

    @media (max-width: 650px) {
        margin-bottom: ${(props) => props.margin ? "50px" : ""};
    }
`
export const TextMiddle = styled.div`
    position: absolute;
    width: 70%;
    top: 80%;
    left: ${(props) => props.left};
    text-align: center;
    cursor: pointer;

    @media (max-width: 650px) {
        top: 80%;
        width: 100%;
        left: 0px;
        font-size: 20px;
        text-align: center;
    }
`

export const ContainerPasos = styled.div`
    border-radius: 5px;
    border: solid 0.5px #d2d2d2;
    margin: 15px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 80%;
        padding: 25px;
        margin-bottom: 20px;
    }
    
    
`

export const Oval = styled.div`
    width: 35px;
    height: 35px;
    background-color: #30735f;
    border-radius: 100px;
`


export const PasoTitle = styled.div`
    width: 100%;
    text-align: left;
    
    font-size: 18px;
    font-weight: 700;
    line-height: 1.39;  
    padding-left: 3px;
    color: #0c2b3d;
    margin-top: 3%;

    @media (max-width: 650px) {
        color: #0c2b3d;
        font-size: 30px;
        
    }
`

export const PasoSubTitle = styled.div`
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    
    line-height: 1.39;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    
    padding-left: 3px;
    color: #0c2b3d;
    margin-top: 3%;

    @media (max-width: 600px) {
        color: #0c2b3d;
        font-size: 22px;
    }
`

class groupImage extends React.Component {
    state = {
        active: "admin"
    }

    refreshActive = (active) => {
        this.setState({ active: active })
        this.props.handleHover(active)
        $(document).ready(function () {
            $("html, body").animate({
                scrollTop: $("#aca").offset().top + $("#aca").height() / -10
            }, 1500);
        });
    }

    render() {
        return (
            <ContainerGlobal>
                <Title>¿Cómo convertirme en un embajador de Miiii?</Title>
                <Container>
                    <ContainerPasos>
                        <ImageSteps src={pasouno} />
                        <PasoTitle>Llená tus datos</PasoTitle>
                        <PasoSubTitle>Completá todos los datos del formulario para que podamos validarlos y ponernos en contacto con vos.</PasoSubTitle>
                    </ContainerPasos>
                    <ContainerPasos>
                        <ImageSteps src={pasodos} />
                        <PasoTitle>¡Comunicá!</PasoTitle>
                        <PasoSubTitle>Tu principal misión es poder llevar nuestra plataforma al lugar donde vivís o a alguna comunidad que conozcas.</PasoSubTitle>
                    </ContainerPasos>
                    <ContainerPasos>
                        <ImageSteps src={paso1tres} />
                        <PasoTitle>Disfrutá los beneficios</PasoTitle>
                        <PasoSubTitle>Una vez adoptado Miiii en al menos un barrio, comunidad o consorcio, te bonificaremos <strong>2 expensas</strong> de hasta $60.000.</PasoSubTitle>
                    </ContainerPasos>
                </Container>
            </ContainerGlobal>
        )
    }
}


export default groupImage
