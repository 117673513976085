import React from 'react'
import styled from 'styled-components'
import miBlanco from "../../assets/miblanco.svg"
import background from "../../assets/background.svg"
import backgroundmobile from "../../assets/backgroundmobile.svg"
import check from "../../assets/checked.svg"
import itau from "../../assets/itau.png";

import FormEmbajadores from '../form';

export const ImageBackground = styled.img`
   z-index: -1;
   width: 100vw;
   position: absolute;
   top: -12%;

   @media only screen and (max-width: 600px) {
        display: none;
        justify-content: center;
        align-items: center;
    }
`

export const ImageBackgroundMobile = styled.img`
   display: none;
   top: 0px;
   @media only screen and (max-width: 600px) {
        display: inline;
        z-index: -1;
        width: 100vw;
        height: 85vh;
        position: absolute;
        top: 0px;
    }
`

export const ImgLogo = styled.img`
    @media only screen and (max-width: 600px) {
        width: 25vw;
        margin: 2% 0%;
    }
   width: 10vw;
   margin: 2% 0%;
`

export const ImgLogoItau = styled.img`
   width: 70px;
   margin: 2% 0%;
   float: left;
margin-right: 20px;
@media only screen and (max-width: 700px) {
    float: none;
}
`

export const Wrapper = styled.div`
    /*wrapper*/
    width: 100vw;
    height: 128vh;
    overflow: hidden;

    @media only screen and (max-width: 700px) {
      //  background: ${(props) => props.url ? 'url("' + props.url + '") no-repeat fixed center;' : 'url(img_tree.gif) no-repeat fixed center;'};  
        height: auto;
    }
`

export const Container = styled.div`
    padding: 5%;
    width: 90%; 
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        display: block;
        justify-content: center;
        align-items: center;
    }


`

export const Containerimg = styled.div`
    width: 100%; 
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
        }
`

export const ContainerimgItau = styled.div`
    width: 100%; 
    text-align: left;
    position: absolute;
    top: 86%;
    margin-left: 5%;
    z-index: -1;
    @media only screen and (max-width: 600px) {
        text-align: center;
        position: unset;
        top: unset;
        margin-left: unset;
        }
`



export const ContainerText = styled.div`
    width: ${(props) => props.width ? props.width : '50%'};  
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 600px) {
        display: block;
        width: 95%;
        }
`
export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
    
`
export const Title = styled.div`  
    color: #ffffff; 
    

    font-size: 36px;
    line-height: 1.15;
    font-weight: ${(props) => props.bold ? "900" : "400"};  
    @media only screen and (max-width: 650px) {
        font-size: 22px;  
}
    
`

export const TitleItau = styled.div`  
color: #000;
font-size: 20px;
line-height: 1.45;
width: 45%;
margin-top: 20px;
    font-weight: ${(props) => props.bold ? "900" : "400"};  
    @media only screen and (max-width: 650px) {
        font-size: 20px;  
        width: 88%;
        margin: 20px 10px 20px 10px;
} 
`

export const SubTitleItau = styled.div`  
    color: #000; 
    color: #000;
    font-size: 13px;
    line-height: 1.45;
    font-weight: 400;
    width: 40%;
    line-height: 1.45;
    font-weight: ${(props) => props.bold ? "900" : "400"};  
    @media only screen and (max-width: 650px) {
        font-size: 14px;  
        width: 85%;
        margin: 25px;
        
} 
`

export const Subtitle = styled.div`  
    color: #ffffff; 
    font-size: 15px;  
    
    margin: ${(props) => props.margin ?  props.margin : "10% 0%"};  
`
export const Item = styled.div` 
    display: flex;
    flex-direction: row;
    color: #ffffff; 
    font-size: 15px;  
    font-weight: 900;
    margin: 1% 0%;
    align-items:  ${(props) => props.alingUp ? "end" : "center"};   
    @media only screen and (max-width: 800px) {
    margin-bottom: 22px;
    }

`
export const Check = styled.img`  
    width: 25px;
    margin: 0% 2%;
`

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false })
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }

    render() {
        return (
            <Wrapper>
                <Container>
                    <ContainerText width={'55%'}>
                        <Containerimg>
                            <ImgLogo src={miBlanco} />
                        </Containerimg>
                        <ContainerTitle>
                            <Title>Convertite en embajador de </Title>
                            <Title bold={true}>Miiii y obtené hasta $60.000  </Title>
                            <Title>de bonificación en tus expensas</Title>
                        </ContainerTitle>
                        <ContainerText width={'90%'}>
                            <Subtitle margin={'3%'}>Y además accedés a:</Subtitle>
                            <Item alingUp="true">
                                <Check src={check} />
                                <>1 cuenta Itaú Personal Bank sin cargo de mantenimiento</>
                            </Item>
                            <Item>
                                <Check src={check} />
                                <>1 Tarjeta Mastercard Platinum o Black sin cargo</>
                            </Item>
                            <Item>
                                <Check src={check} />
                                <> Descuentos especiales en compras y salidas</>
                            </Item>
                        </ContainerText>
                    </ContainerText>
                    <ContainerText width={'45%'}>
                        <FormEmbajadores />
                    </ContainerText>
                </Container>
                <ImageBackground src={background} />
                <ImageBackgroundMobile src={backgroundmobile} />
                <ContainerimgItau>
                    <ImgLogoItau src={itau} /> 
                    <TitleItau>Con el respaldo del banco más grande de Latinoamérica</TitleItau>
                    <SubTitleItau>Banco Itaú Argentina es una sociedad anónima según la ley argentina, sus accionistas responden por las operaciones del banco, solo hasta la integración de las acciones suscriptas (Ley 25.738)</SubTitleItau>
                </ContainerimgItau>
            </Wrapper >
        )
    }
}


export default Header
