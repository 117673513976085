import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    outline: none;
    font-size: 18px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
`

const Input = props => <StyledInput {...props} />

export default Input
