import React from 'react'
import styled from 'styled-components'

import factura from '../../assets/factura.svg';
import qrcode from '../../assets/qr-code.svg';
import transferencia from '../../assets/transferencias.svg';
import wallet from '../../assets/wallet.svg';

import newspaper from '../../assets/newspaper-folded.svg';
import calendar from '../../assets/calendar.svg';
import happyface from '../../assets/happyface.svg';
import shopping from '../../assets/shopping-bag.svg';

import price from '../../assets/price.svg';
import chat from '../../assets/chat.svg';
import clock from '../../assets/clock.svg';
import safety from '../../assets/safety.svg';


const Container = styled.div`
    border-radius: 5px;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: #30735f;

    margin-top: ${(props) => props.marginTop ? props.marginTop : ""};
    
    @media (max-width: 650px) {
        flex-direction: column; 
        padding: 0%;
        width: 100vw;
        margin: 0px;
    }
`

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;  
    @media (max-width: 650px) {
        width: 100%;
        margin: 0%;
        padding: 0%;
    }
`

const ImagenApp = styled.img`
    margin: 15px;
    widht:80%;
`


const ImagenPasos = styled.img`
    margin: 15px;
    widht:80%;
`
const Button = styled.div`
    color: white;
    font-size: 20px;
    font-weight:${(props) => props.active ? "900" : "400"};
    background-color:${(props) => props.active ? "#6e9d8f" : "#30735f"};  
    border-radius: ${(props) => props.right ? "7px 0px 0px 7px;" : "0px 7px 7px 0px"};  
    border: solid 0.5px #6e9d8f;
    width: 185px;
    height: 45px;
    text-align: center;
    line-height: 2.2;
    cursor: pointer;


    @media (max-width: 650px) {
      
    }
`

const ImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 650px) {
       display: flex;
       flex-direction: column;
       wrap: no-wrap;
       margin: 5%;
       widht: 100%;
       align-items: center;
       text-align: center;
    }
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2% 0% 0% 0%;
    
    @media (max-width: 650px) {
       display: flex;
       flex-direction: row;
       wrap: no-wrap;
       widht: 100%;
       margin: 5% 0%;
        
    }
`

const TitleDescription = styled.div`
    height: ${(props) => props.height ? props.height : "50px"};
    font-size: ${(props) => props.size ? props.size : "35px"};
    font-weight:${(props) => props.bold ? "900" : "400"};
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.color ? props.color : " #fff"};
    margin: ${(props) => props.margin ? props.margin : ""};

    @media (max-width: 650px) {
        font-size: 21px;
        height: auto;
        line-height: 1.1;
        text-align: unset;
        color: ${(props) => props.color ? props.color : " #fff"};
        font-weight: ${(props) => props.bold ? "900" : "400"};
    }
`
const LabelDescription = styled.div`

    width: ${(props) => props.width ? props.width : "100%"};
    font-size: ${(props) => props.size ? props.size : "18px"};
    line-height: ${(props) => props.lineHeight ? props.lineHeight : "1.5"};
    
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;

    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: ${(props) => props.margin ? props.margin : ""};


    
    @media (max-width: 650px) {
        margin: 0px;
        font-weight: 400;
        font-size: 13px;
        width: 100%;
        text-align: center;
    }
`
const Rectangulo = styled.div`  
    border-radius: 7.3px;
    background-color: rgba(255, 255, 255, 0.3);

    
    @media (max-width: 650px) {
        margin-bottom: 14px;
        width: 85%;
    }
`
const GridContainer = styled.div`  
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 40px;
    padding: 5% 10%;
    
    @media (max-width: 650px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 40px;
    }
`



class muchoMas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modoAdministrador: false,
            modoPropietario: true
        }
    }

    toggle() {
        this.setState({ modoPropietario: !this.state.modoPropietario, modoAdministrador: !this.state.modoAdministrador })
    }

    render() {
        return (
            <>
                <Container marginTop={"10%"}>
                    <Row>
                        <TitleContainer>
                            <TitleDescription height={"70px"}>Más que un app,</TitleDescription>
                            <TitleDescription height={"70px"} bold={true}>una superapp</TitleDescription>
                        </TitleContainer>
                    </Row>
                    <Row>
                        <TitleContainer>
                            <Button right={true} active={this.state.modoPropietario} onClick={() => this.toggle()}>Soy propietario</Button>
                            <Button active={this.state.modoAdministrador} onClick={() => this.toggle()}>Soy administrador</Button>
                        </TitleContainer>
                    </Row>
                    {this.state.modoPropietario ?
                        <GridContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={factura} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Pagá tus expensas y tus servicios, fácil y rápido</LabelDescription>
                            </ImgContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={qrcode} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Pagá con QR en comercios adheridos</LabelDescription>
                            </ImgContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={transferencia} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Recibí y enviá dinero a cualquier cuenta</LabelDescription>
                            </ImgContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={wallet} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Billeteras para tu familia, ¡incluso los más chicos!</LabelDescription>
                            </ImgContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={newspaper} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Recibí noticias de todo lo que pasa en tu comunidad</LabelDescription>
                            </ImgContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={calendar} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Reservá espacios comunes fácil y rápido</LabelDescription>
                            </ImgContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={happyface} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Invitá a tus amigos y controlá los ingresos a tu casa</LabelDescription>
                            </ImgContainer>
                            <ImgContainer>
                                <Rectangulo>
                                    <ImagenApp src={shopping} />
                                </Rectangulo>
                                <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Comprá y vendé lo que quieras con Shops</LabelDescription>
                            </ImgContainer>
                        </GridContainer>
                        :
                        null
                    }
                    {this.state.modoAdministrador ?
                        <GridContainer>
                            
                                <ImgContainer>
                                    <Rectangulo>
                                        <ImagenApp src={price} />
                                    </Rectangulo>
                                    <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Control fácil y eficaz de los pagos realizados</LabelDescription>
                                </ImgContainer>

                                <ImgContainer>
                                    <Rectangulo>
                                        <ImagenApp src={chat} />
                                    </Rectangulo>
                                    <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Comunicación rápida y directa con los vecinos</LabelDescription>
                                </ImgContainer>
                            
                                <ImgContainer>
                                    <Rectangulo>
                                        <ImagenApp src={clock} />
                                    </Rectangulo>
                                    <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Administrá las reservas de los vecinos</LabelDescription>
                                </ImgContainer>
                                <ImgContainer>
                                    <Rectangulo>
                                        <ImagenApp src={safety} />
                                    </Rectangulo>
                                    <LabelDescription margin={"6% 0% 10% 0%"} lineHeight={'1'} width={'80%'} size={'16px'}>Ingresos a las viviendas más ágiles y seguros</LabelDescription>
                                </ImgContainer>
                            
                        </GridContainer>
                        :
                        null
                    }
                </Container>
            </>
        )
    }
}

export default muchoMas
