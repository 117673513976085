import React from 'react'
import styled from 'styled-components'
import $ from "jquery"
import logoNordelta from "../../assets/logoNordelta.svg"
import logoPuertos from "../../assets/logoPuertos.svg"


export const Title = styled.div`
  width: 100%;
  height: 100px;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #182C45;
  margin-top: 6%;

    @media (max-width: 650px) {
        font-size: 30px;
        height: 50px;
        margin-bottom: 29px;
        margin-top: 30px;
    }
`
export const Container = styled.div`
    
    transition: all 1s ease-in-out;

    @media (max-width: 650px) {
        flex-direction: column;
        width: 100%;
        display: contents;
        margin: 0%;

    }
`
export const ImageStyled = styled.img` */
    width: 18vw;
    margin-right: ${(props) => props.margin ? "2vw" : ""};
    transition: all 0.5s ease-in;
    &:hover{
        transition: all 0.5s ease-in;
        transform: scale(1.1,1.1) ;
    }     
    @media (max-width: 650px) {
        width: 50%;     
        margin-bottom: 50px;
    }
`

export const Label = styled.div`
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    @media (max-width: 650px) {
        bottom: 40px;
        width: 100%;
        font-size: 20px;
        text-align: center;
        display: contents;
    }

`
export const ContainerImages = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: space-evenly; 

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
    }
    `

export const ContainerImage = styled.div`
    width: 18vw; 
    width: 25vw;
    transition: all 1s  ease-in-out;
    margin: 0% 2%;
    @media (max-width: 650px) {
        margin-bottom: ${(props) => props.margin ? "50px" : ""};
    }
`
class groupImage extends React.Component {
    state = {
        active: "admin"
    }

    refreshActive = (active) => {
        this.setState({ active: active })
        this.props.handleHover(active)
        $(document).ready(function () {
            $("html, body").animate({
                scrollTop: $("#aca").offset().top + $("#aca").height() / -10
            }, 1500);
        });
    }

    render() {
        return (
            <>
                <Title>Confían en nosotros</Title>
                <ContainerImages>
                    <Container>
                        <ImageStyled src={logoPuertos} margin={true} />
                    </Container>
                    <Container>
                        <ImageStyled src={logoNordelta} margin={true} />
                    </Container>
                </ContainerImages>
            </>
        )
    }
}


export default groupImage
