import React from 'react'
import styled from 'styled-components'
import loader from "../assets/loadergreen.gif"

export const Imgstyled = styled.img`
    display: block;
    margin-left: auto;
    margin-top: 20%;
    margin-right: auto;
    width: 20%;
   
`

class Loader extends React.Component {
    render() {
        return (
            <Imgstyled src={loader}/>
        )
    }
}


export default Loader
